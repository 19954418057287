body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app.error,
.app.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.app.loading .spinner {
  margin: 40px;
}

/* The arrow in the roles submenu is not aligned properly, so let's hide it */
.roles-submenu .ant-dropdown-menu-submenu-arrow {
  display: none;
}

/* Remove padding around highlighted search words */
.ant-table-row mark {
  padding: 0;
}

.selectable-row:hover {
  cursor: pointer;
}

.disabled-row:hover {
  cursor: not-allowed;
}

/* Hide week number in WeekPicker */
.ant-calendar-week-number-header,
.ant-calendar-week-number-cell {
  display: none;
}

.ant-menu-item .ant-badge-dot {
  box-shadow: none;
  -webkit-box-shadow: none;
}

/* Reposition badge count when menu is collapsed */
.ant-menu-inline-collapsed .ant-badge-dot {
  top: 10px;
}

/* Override tooltip transparency */
.ant-tooltip-inner {
  background-color: rgba(0, 0, 0, 0.90);
}

/* Hide badge count in tooltip */
.ant-tooltip-content .ant-badge-dot {
  opacity: 0;
}

/* Disable animation on Ant badge */
.ant-menu-item .ant-badge-zoom-appear {
  animation: none;
  -webkit-animation: none;
}

/* Fix menu link colors for IE11 */
.ant-menu-item .ant-badge {
  color: rgba(255, 255, 255, 0.65);
}
.ant-menu-item-active .ant-badge {
  color: #fff;
}

.qr-code img {
  max-height: 100%;
  max-width: 100%;
}

.label-align-top .ant-legacy-form-item-label {
  text-align: left;
  width: 100%;
}

/* Prevent Descriptions table from being full width */
.chirp-descriptions {
  display: inline-block;
  clear: both;
}

/* Hide dividers between table columns */
.ant-table-thead th::before {
  display: none;
}

.help-text {
  color: rgba(0, 0, 0, 0.45);
  margin-top: 5px;
  font-size: 13px;
  line-height: 17px;
}

/* Override Step component styles in SendInvitationForm */
.send-invitation-steps.ant-steps-vertical .ant-steps-item-content {
  min-height: 40px;
}

.send-invitation-steps .ant-steps-item-tail::after {
  display: none;
}

.send-invitation-steps .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}

.send-invitation-steps .ant-steps-item-error .ant-steps-item-icon {
  border-color: rgba(0, 0, 0, 0.25);
}

.send-invitation-steps .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}

.property-map-wrapper {
  overflow: hidden !important;
}

.property-map-wrapper .ant-modal-body {
  padding: 0;
}

.iframe-wrapper .ant-spin.ant-spin-spinning {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
